import React, { Fragment } from 'react';
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
// import ThreeDRotation from '@material-ui/icons/ThreeDRotation'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DrawerLayout from '../components/drawerLayout';
import ImageSlider from "../components/imageSlider";
// import RubleIcon from "../components/rubleIcon";
// import RubleEmptyIcon from "../components/rubleEmptyIcon";
// import RatingEx from "../components/ratingEx";
import Button from '@material-ui/core/Button';
import { getCollectionPageName } from '../components/tools';
import { useCardStyles, useRatingStyles, useMenuStyles } from '../components/styles';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress'
import HomeIcon from '@material-ui/icons/Home';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Tooltip from '@material-ui/core/Tooltip';
import { YaAdvBox } from '../components/YaAdvBox';

function CollectionCard(props) {
  const brand = props.brand;
  const collection = props.collection;
  const designs = collection.designs.slice(0).reverse();
  const cardClasses = useCardStyles();
  const ratingClassess = useRatingStyles();
  const [designIndex, setDesignIndex] = React.useState(0);
  const dddBaseUrl = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : '';

  return (
    <Card className={cardClasses.card}>
      <CardHeader
        avatar={
          <Avatar aria-label={brand.fullName} style={{ backgroundColor: brand.color }}>
            {brand.name[0].toUpperCase()}
          </Avatar>
        }
      //   action={
      //     <>
      //       <Tooltip title="Посмотреть в 3D" placement="top" arrow>
      //         <IconButton aria-label="Посмотреть в 3D" href={ dddBaseUrl + '/app/?b=' + brand.name + '&c=' + collection.name + '&d=' + (designs[designIndex].id || designs[designIndex].index) } color="secondary">
      //           <ThreeDRotation />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="Посмотреть все дизайны" placement="top" arrow>
      //         <IconButton aria-label="Подробнее" variant="contained" component={Link} to={ '/' + collection.brand + '/' + getCollectionPageName(collection.name) + '/' } color="primary">
      //           <ArrowForwardIcon />
      //         </IconButton>
      //       </Tooltip>
      //     </>
      // }
        title={collection.name}
        subheader={brand.fullName}
      />
      <CardMedia>
        <ImageSlider
          onChangeIndex={ (index) => { setDesignIndex(index) } }
          src = { designs.map((design) => ('/images/' + brand.name + '/' + design.images[0])) }
          alt = { designs.map((design) => (collection.name + ', ' + design.name)) }
        />
      </CardMedia>
      <CardActions>
        <Button 
          size="small"
          variant="contained"
          color="primary"
          href={ dddBaseUrl + '/app/?b=' + brand.name + '&c=' + collection.name + '&d=' + (designs[designIndex].id || designs[designIndex].index) }
        >Посмотреть в 3D</Button>
        <Button 
          size="small"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          href={ '/' + collection.brand + '/' + getCollectionPageName(collection.name) + '/' }
        >Все дизайны</Button>
      </CardActions>
      {/* <CardActions disableSpacing>
        <RatingEx
          classes={ratingClassess}
          value={designs[designIndex].priceLevel}
          readOnly
          precision={0.5}
          icon={<RubleIcon fontSize="inherit" />}
          emptyIcon={<RubleEmptyIcon fontSize="inherit" />}
          size="small"
        />
      </CardActions> */}
    </Card>
  );
}


function BrandPageTemplate(props) {
  const { container, data } = props;
  const brand = data.brandsJson;
  const menuClasses = useMenuStyles();

  const allCollectionsMap = data.allDesignsJson.nodes.reduce((map, item) => {
    let group = map.get(item.collection);
    if(!group)
      map.set(item.collection, group = { name: item.collection, brand: item.brand, designs: [] });
    group.designs.push(item);
    return map;
  }, new Map());

  const allCollections = Array.from(allCollectionsMap.values());

  const [loadedCollections, setLoadedCollections] = React.useState(allCollections.slice(0, 9));
  const [hasMore, setHasMore] = React.useState(loadedCollections.length < allCollections.length);

  const loadFunc = function() {
    setLoadedCollections(allCollections.slice(0, loadedCollections.length + 18));
    setHasMore(loadedCollections.length < allCollections.length)
  }

  const loader = <Box width="100%" align="center" key="loader"><CircularProgress disableShrink /></Box>;

  const advStartIndex = 0;
  const advRepeat = 8;
  const advCount = Math.ceil((loadedCollections.length - advStartIndex) / advRepeat);
  const totalWithAdv = loadedCollections.length + advCount;

  return (
    <DrawerLayout
      container={container}
      title={brand.fullName + ' фото интерьеров ванных комнат'}
      menu={
        <List dense={true}>
          <ListItem button component={Link} to="/">
            <ListItemAvatar>
              <Avatar aria-label="на главную страницу">
                <HomeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ПлиткаБот" secondary={data.allBrandsJson.totalCount + ' брендов'} />
          </ListItem>
          <ListItem button selected>
            <ListItemAvatar>
              <Avatar aria-label={brand.fullName} style={{ backgroundColor: brand.color }}>
                {brand.name[0].toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={brand.fullName} secondary={allCollections.length + ' коллекций'} />
          </ListItem>
          <List dense={true} component="div" disablePadding>
            {allCollections.map((collection, index) => (
              <ListItem button component={Link} to={ '/' + collection.brand + '/' + getCollectionPageName(collection.name) } key={index} className={menuClasses.nested1}>
                <ListItemAvatar>
                  <Avatar><PhotoLibraryIcon /></Avatar>
                </ListItemAvatar>
                <ListItemText primary={collection.name} secondary={collection.designs.length + ((collection.designs.length < 5) ? ' дизайна' : ' дизайнов')} />
              </ListItem>
            ))}
          </List>
        </List>
      }
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={ `Варианты раскладки плитки ${brand.fullName}, ${data.allDesignsJson.nodes.length} фото классических и современных интерьеров ванных комнат` } />
        <title>Раскладка плитки {brand.fullName}, {'' + data.allDesignsJson.nodes.length} фото интерьеров ванных комнат</title>
        <script src="https://yandex.ru/ads/system/context.js" async></script>
      </Helmet>
      <InfiniteScroll loadMore={loadFunc} hasMore={hasMore} loader={loader}>
        <Box m={0} p={0} display="flex" flexWrap="wrap" justifyContent="space-between">
          {
            loadedCollections.map(
              (collection, index) => (
                <Fragment key={index}>
                  { (index % advRepeat) === advStartIndex ? ( <YaAdvBox advId={index} /> ) : (null) }
                  <CollectionCard brand={brand} collection={collection} />
                </Fragment>
              )
            )
          }
          {
            Array(3 - totalWithAdv % 3).fill().map((item, index) => (
              <Box ml={1} mr={1} style={{flex: "1 1 30%"}} minWidth="300px" height={0} key={'extra-' + index}></Box>
            ))
          }
        </Box>
      </InfiniteScroll>
    </DrawerLayout>
  );
}

export default BrandPageTemplate;

export const query = graphql`
  query ($brand: String!) {
    brandsJson(name: {eq: $brand}) {
      name
      fullName
      color
    }
    allDesignsJson(filter: {brand: {eq: $brand}}) {
      nodes {
        brand
        collection
        id
        name
        images
      }
    }
    allBrandsJson {
      totalCount
    }
  }
`
